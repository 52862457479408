import React from 'react';
import { FormDataConsumer, NumberInput, ReferenceInput, required, SelectInput, TextInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import { challengesTaskTypes } from '../config/constants';
import ImageInput from '../../../commonComponents/UploadInputs/ImageInput';
import { isHungary, isIreland } from '../../../helpers/appTypeHelper';
import { s3FilePaths } from '../../../config/constants';

const useStyles = makeStyles({
  customFormField: {
    width: 256,
  },
});

const tasksEntryReferenceOptions = {
  [challengesTaskTypes.knowledgeBaseEntity]: ['admin/knowledge-bank/articles', 'Won entity'],
  [challengesTaskTypes.wonKnowledgeBaseEntity]: ['admin/knowledge-bank/articles', 'Entity'],
  [challengesTaskTypes.specialQuiz]: ['admin/special-quizzes', 'Special quiz'],
  [challengesTaskTypes.lottery]: ['admin/lotteries', 'Chance'],
  [challengesTaskTypes.dailyQuestion]: ['admin/daily-questions', 'Daily question', 'text'],
  [challengesTaskTypes.wonDailyQuestion]: ['admin/daily-questions', 'Won daily question', 'text'],
  [challengesTaskTypes.salesTips]: ['admin/sales-tips', 'Sales tips'],
};

const tasksWithEntry = [
  challengesTaskTypes.knowledgeBaseEntity,
  challengesTaskTypes.wonKnowledgeBaseEntity,
  challengesTaskTypes.specialQuiz,
  challengesTaskTypes.lottery,
  challengesTaskTypes.dailyQuestion,
  challengesTaskTypes.wonDailyQuestion,
  challengesTaskTypes.salesTips,
];

const tasksWithKbEntry = [challengesTaskTypes.knowledgeBaseEntity, challengesTaskTypes.wonKnowledgeBaseEntity];

const tasksTypesWithoutCount = [
  challengesTaskTypes.custom,
  challengesTaskTypes.customTextManual,
  challengesTaskTypes.customTextAuto,
  challengesTaskTypes.linkToChat,
  ...tasksWithEntry,
];

const Task = ({ member, taskFormType, itemCountFieldLabel, index }) => {
  const classes = useStyles();
  const [reference, label, optionText = 'name'] = tasksEntryReferenceOptions[taskFormType] || [];
  const isKbEntry = tasksWithKbEntry.includes(taskFormType);

  const isRequiredDescriptionValidation = () => {
    const isRequiredForTeamChallenge =
      (isHungary() || isIreland()) &&
      (taskFormType === challengesTaskTypes.customTextAuto || taskFormType === challengesTaskTypes.customTextManual);

    const isRequiredForLinkToChat = isIreland() && taskFormType === challengesTaskTypes.linkToChat;

    const isRequired = isRequiredForTeamChallenge || isRequiredForLinkToChat;

    return isRequired;
  };

  return (
    <>
      {!tasksTypesWithoutCount.includes(taskFormType) && (
        <NumberInput source={`${member}.item_count`} label={itemCountFieldLabel} className={classes.customFormField} />
      )}
      {taskFormType === challengesTaskTypes.quizzesOnTopicNumber && (
        <ReferenceInput
          label="Topic name"
          source={`${member}.brand_id`}
          reference="admin/brands"
          filter={{ positive: true, active: true }}
          className={classes.customFormField}
          perPage={100}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      )}
      {taskFormType === challengesTaskTypes.linkToChat && (
        <>
          <TextInput
            source={`${member}.chat_button_link`}
            label={'Add Link'}
            className={classes.customFormField}
            validate={required()}
          />
          <TextInput source={`${member}.caption_text`} label={'Caption Text'} className={classes.customFormField} />
          <TextInput source={`${member}.message_text`} label={'Message Text'} className={classes.customFormField} />
          <ImageInput label="Message photo" imageInputSource={`${member}.image`} />
        </>
      )}
      {taskFormType === challengesTaskTypes.customTextAuto && (
        <TextInput
          source={`${member}.correct_answer`}
          validate={required()}
          label="Correct answer"
          className={classes.customFormField}
        />
      )}
      {taskFormType === challengesTaskTypes.kbGamesWonNumber && (
        <ReferenceInput
          label="Category"
          source={`${member}.knowledge_bank_category_id`}
          reference="admin/knowledge-bank/categories"
          perPage={100}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      )}
      {taskFormType === challengesTaskTypes.custom && (
        <ImageInput label="Photo frame" imageInputSource={`${member}.image`} imagePath={s3FilePaths.allUploads} />
      )}
      {tasksWithEntry.includes(taskFormType) && (
        <>
          {isKbEntry && (
            <ReferenceInput
              label="Category"
              source={`${member}.knowledge_bank_category_id`}
              reference="admin/knowledge-bank/categories"
              perPage={100}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          )}
          <FormDataConsumer>
            {({ formData }) => {
              if (!formData) {
                return null;
              }

              const selectedCategoryId = formData.tasks[index]?.knowledge_bank_category_id;

              return (
                <ReferenceInput
                  label={label}
                  reference={reference}
                  source={`${member}.entity_id`}
                  filter={selectedCategoryId ? { category_id: selectedCategoryId } : {}}
                  perPage={100}
                >
                  <SelectInput optionText={optionText} />
                </ReferenceInput>
              );
            }}
          </FormDataConsumer>
        </>
      )}
      <TextInput
        source={`${member}.description`}
        label="Description"
        validate={isRequiredDescriptionValidation() && required()}
        className={classes.customFormField}
      />
    </>
  );
};

export default Task;
